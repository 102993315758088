import React from "react";
import "./footer.css";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="footerstyle1">
        <div className="footer1">
          <div className="row1  ">
            <div>
              <div className="title123">About Us</div>
              <p><Link to="/aboutus/about"  className="noStyle1">Who we are</Link></p>
              <p><Link to="/aboutus/testimonials"  className="noStyle1">Testimonials</Link></p>
              {/* <p><Link to="/contactus"  className="noStyle1">Contact Us</Link></p> */}
            </div>
            <div>
            <div className="title123">Quick Links</div>
              <p>
                <Link to="/tools/SIP" className="noStyle1">Tools</Link>
                <br /></p>
                <p>
                {/* <a href="" className="noStyle1">Contact</a> */}
              </p>
            </div>
            <div>


            <div className="title123">Our Services</div>
            <p>
                <Link to="/products/market" className="noStyle1">Stock Broking</Link>
                <br /></p>
                <p>
                <Link to="/products/mutual_fund" className="noStyle1">Mutual Fund</Link>
              </p>
              <p>
                <Link to="/products/insurance" className="noStyle1">Insurance</Link>
                <br /></p>
                <p>
                <Link to="/products/others/pms" className="noStyle1">Other</Link>
              </p>
            </div>
            <div>

            <div className="title123">Get in touch</div>

            <div>
            <p >
              <a href="https://maps.app.goo.gl/7RXJKrPdWDyPVqa46" className="noStyle1"><LocationOnIcon/>Ground floor, office no.3, Vidhata appartment<br/>Ram maruti road, opp. shivaji park police station<br/>Dadar, Mumbai-400028</a>
              
              <p style={{display:"flex" }}><LocalPhoneIcon/>&nbsp; 9920369415/ 9769442478 </p>
              <p style={{display:"flex"}}><MailIcon/>&nbsp;trendfinserv@gmail.com</p>
            </p>
            </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
